import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

import ImgBackground from "components/heroes/ImgBackground"
import TitleBox from "components/titles/Titlebox"
import Section50Background from "components/heroes/Section50Background"
import ThreeStepSolution from "components/blocks/ThreeStepSolution"

import SubmenuAbout from "../components/menus/submenu-about"



const AboutUs = () => {

  // using static header image, load here
  const data = useStaticQuery(
    graphql`
    query {
      heroImage: file(relativePath: { eq: "backgrounds/group-tankers.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
          )
        }
      }
      quoteImage: file(relativePath: { eq: "backgrounds/tanker-sunset.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
          )
        }
      }
    }
  `
  )

  const factsTable = [
    { th: "Trading Market", td1: "US OTCQB" },
    { th: "Ticker Symbol", td1: "PGTK" },
    { th: "2-year Orderbook", td1: "USD $200m+" },
    { th: "FY2020 Revenue", td1: "USD $130m" },
    { th: "FY2020 Net Income", td1: "USD $10.3m" },
    { th: "Offices", td1: "USA, UK, China, Canada, Sydney" },
  ]


  return (
    <Layout>
      <Seo title="About Us" />

      {/* Title banner */}
      <ImgBackground image={data.heroImage}>
        <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-60 lg:flex z-2">
          <TitleBox
            title="Why choose Pacific Green?"
            caption="About Us"
          >
          </TitleBox>
        </div>
      </ImgBackground>

      {/* content and map */}
      <section className="py-8 md:py-16 lg:py-32">
        <div className="container lg:flex lg:space-x-12">

          <div className="lg:w-1/2">
              <SubmenuAbout />
          </div>

          <div className="prose max-w-none">
            <p className="text-2xl">As specialists in this field we have the experience of more than 120 scrubber installations and our vast global network of service facilities ensures that our customers enjoy the best and most comprehensive after-sales service possible.</p>
            <p>As a global company with the know-how and experience in a wide range of greentech engineering and manufacturing sectors, Pacific Green is able to take on and beat the competition with class winning efficiency and lower running costs.  </p>
            <p>As a consequence, ship owners are increasingly turning to Pacific Green to meet the environmental challenges faced by exhaust gas emission controls worldwide.</p>
          </div>

        </div>
      </section>

      {/* service overview section */}
      <section className="py-16 border-t border-b bg-primary-50 border-gray-100">
        <div className="container">

          <h2 className="mb-8 text-center">
            All the ingredients you need
          </h2>

          <div className="lg:flex lg:space-x-12 prose max-w-none">

            <div className="mb-12 lg:w-1/2 2xl:w-7/12 lg:mb-0">

              <h3 className="text-primary">Leading performance</h3>
              <p>ENVI-Marine™ performance comfortably meets the required emission limit regulations for both ECA and IMO 2020 regulations in either ‘open’ or ‘closed’ modes.</p>

              <h3 className="text-primary">Small, flexible footprint</h3>
              <p>The ENVI-Marine™ System is small compared to most other systems. Our patented TurboHead™ technology allows for a footprint that is considerably smaller without compromising on efficiency.</p>

              <h3 className="text-primary">Attractive CAPEX / OPEX</h3>
              <p>The advanced TurboHead™ technology, careful material selection and skilled engineering design ensure competitive initial pricing and cost effective operation and maintenance.</p>

            </div>

            <div className="mb-12 lg:w-1/2 2xl:w-5/12 lg:mb-0">

              <h3 className="text-primary">First-class after-sales service</h3>
              <p>Our technology requires only minimal maintenance but we can take even that small effort off our customers hands with a complete after-sales service package that can be
                delivered anywhere around the world.</p>

              <h3 className="text-primary">Unrivalled Capacity</h3>
              <p>Meeting the challenge of fitting the world's shipping fleet with exhaust gas cleaning systems requires facilities on the largest scale. Pacific Green has the capacity to produce one exhaust scrubber every 48 hours when necessary, from its six major industrial bases.</p>

            </div>

          </div>

        </div>
      </section>

      <section className="py-16 border-b border-gray-100">
          <ThreeStepSolution />
      </section>



      {/* 50/50 image pullquote divider */}
      <section className="">
        <div className="container lg:flex">
          <div className="lg:w-1/2">
            <div className="p-8 md:p-16 lg:p-32 md:pl-0 lg:pl-0 justify-items-end">

              <h2 className="mb-4">A Global Presence</h2>
              <StaticImage src="../images/general/offices-map.png" alt="PGT global offices map" className="my-8" />
              <div className="prose max-w-none">
                <p>Pacific Green Marine Technologies is a wholly owned subsidiary of Pacific Green Group with offices in multiple countries and a large manufacturing base covering multiple greentech sectors. We are already delivering success for our clients around the world. </p>
                <p>With a wealth of experience and a vertically integrated organisation, Pacific Green has the strength and backing to ensure your project also joins in that success.</p>
              </div>

            </div>
          </div>
          <div className="lg:w-1/2">
            <div className="lg:float-left lg:w-1/2-screen lg:h-full lg:flex">
              <Section50Background image={data.quoteImage}>
                <div className="bg-white bg-opacity-90 p-16">

                  <h2 className="text-2xl mb-2">Quick facts</h2>
                  {factsTable.map((item, key) => (
                    <div key={key} className="flex py-2 border-b border-primary-200 border-dashed">
                      <div className="w-1/2 font-bold mr-4">{item.th}</div>
                      <div className="w-1/2">{item.td1}</div>
                    </div>
                  ))}

                </div>
              </Section50Background></div>
          </div>
        </div>
      </section>



    </Layout>
  )
}

export default AboutUs
