import * as React from "react"

const ThreeStepSolution = () => {
  return (
    <div className="container">

      <h2 className="mb-2">Comprehensive Service and Aftercare</h2>
      <p className="text-xl mb-4">Our expert team is on hand to take you though three steps to a full solution customised to your needs</p>

      <div className="prose max-w-none lg:flex lg:space-x-12">
        <div className="mb-8 lg:mb-0 lg:w-1/3">
          <h4 className="text-primary text-xl">1. Discussion &gt; Proposal &gt; Contract</h4>
          <p>Our expert team of Pacific Green Marine engineers will discuss your fleet requirements and work closely with you to create a fully costed proposal, with special care and planning for deployment with minimal impact.</p>
        </div>
        <div className="mb-8 lg:mb-0 lg:w-1/3">
          <h4 className="text-primary text-xl">2. Survey &gt; Plan &gt; Build</h4>
          <p>Once the proposed approach is agreed our design & engineering team will survey your fleet, formulate a plan and then meet your vessels in a port of mutual convenience where an expert team will install your ENVI-Marine device from a combination of prebuilt and custom built components</p>
        </div>
        <div className="mb-8 lg:mb-0 lg:w-1/3">
          <h4 className="text-primary text-xl">3. Implement &gt; Test &gt; Commission</h4>
          <p>Once fleet vessels are launched back to sea with fully tested ENVI-Marine technology in place, we work with you to plan out a service schedule. Also operate with the peace of mind that our Global Service Team are on hand to respond to any support you need while at sea.</p>
        </div>
      </div>
    </div>

  )
}
export default ThreeStepSolution
